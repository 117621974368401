<template>
  <div class="batch-export-container">
    <el-badge v-show="checkedList.length > 0" :value="checkedList.length" class="batch-export" @click.native="handleShowDrawer">
      <div class="batch-export-img">
        <img src="../../../assets/exam/batch-export.png" alt="top">
      </div>
      <div class="batch-export-value">批量导出</div>
    </el-badge>

    <el-drawer :visible.sync="visible" direction="btt" append-to-body>
      <div class="header" slot="title">
        <span class="title">批量导出</span>
        <span>已添加：{{ checkedList.length }}个</span>
      </div>
      <div class="drawer-content">
        <div class="checked-list">
          <div v-for="(item, index) in checkedList" class="checked-item">
            <el-divider v-if="index !== 0"></el-divider>
            <div class="checked-test" :key="item.examId">
              <div class="left">
                <div class="testName">{{ item.testName }}</div>
                <div>
                  <span v-if="superAdmin === '2'" style="margin-right: 14px;">{{ item.gradeName }}</span>
                  <span>{{ item.classNameList }}</span>
                </div>
                <div>考试时间：{{ item.dateStart }}</div>
              </div>
              <div class="right">
                <el-button type="danger" @click="removeItem(item.examId)">移除</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-btn-group mt-10 mb-10">
          <el-button class="clear" @click="clearAll">清空</el-button>
          <el-button class="ml-10" type="primary" @click="batchExport">直接导出</el-button>
          <el-button type="primary" @click="mergeExport">合并导出</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {batchExportByExam, mergeExportByExam} from "@/api/official";

export default {
  name: "BatchExportButton",
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      visible: false,
      superAdmin: 0,
    }
  },
  computed: {
    isSuperTeacher() {
      return this.superAdmin === '2'
    },
    checkedList() {
      return this.list.filter(item => item.checked)
    }
  },
  mounted() {
    this.superAdmin = sessionStorage.getItem('superAdmin')
  },
  methods: {
    handleShowDrawer() {
      this.visible = true
    },
    removeItem(examId) {
      const list = this.list.map(item => {
        if (item.examId === examId) {
          item.checked = false
        }
        return item
      })
      this.$emit('update:list', list)
      this.visible = this.checkedList.length !== 0
    },
    clearAll() {
      const list = this.list.map(item => {
        item.checked = false
        return item
      })
      this.$emit('update:list', list)
      this.visible = false
    },
    // 直接导出
    async batchExport() {
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      const schoolSection = sessionStorage.getItem('schoolSection')
      const localProvinceId = sessionStorage.getItem('schoolProvinceId')
      const provinceId = this.isSuperTeacher ? String(this.$route.query.provinceId) : localProvinceId
      const section = this.isSuperTeacher ? String(this.$route.query.section) : schoolSection
      await this.$confirm('确认导出成绩吗？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          (provinceId === '440000' && section === '2' ? h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制') : ''),
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const convertScore = checked ? 1 : 0
      checked = false
      const data = { convertScore, exams: this.checkedList.map(item => ({ examId: item.examId, clazzIds: this.isSuperTeacher ? item.clazzIds : [item.clazzId] })) }
      const res = await batchExportByExam(data)
      if (res.state === '11') {
        this.$message.success('下载任务已提交，请前往下载中心查看进度')
        this.clearAll()
      } else {
        this.$message.error(res.note)
      }
    },
    // 合并导出
    async mergeExport() {
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      const schoolSection = sessionStorage.getItem('schoolSection')
      const localProvinceId = sessionStorage.getItem('schoolProvinceId')
      const provinceId = this.isSuperTeacher ? String(this.$route.query.provinceId) : localProvinceId
      const section = this.isSuperTeacher ? String(this.$route.query.section) : schoolSection
      await this.$confirm('确认导出成绩吗？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          (provinceId === '440000' && section === '2' ? h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制') : ''),
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const convertScore = checked ? 1 : 0
      checked = false
      const data = {
        exams: this.checkedList.map(item => {
          return {
            examId: item.examId,
            clazzIds: this.isSuperTeacher ? item.clazzIds : [item.clazzId]
          }
        }),
        convertScore
      }
      const res = await mergeExportByExam(data)
      if (res.state === '11') {
        this.$message.success('下载任务已提交，请前往下载中心查看进度')
        this.clearAll()
      } else {
        this.$message.error(res.note)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.batch-export {
  position: fixed;
  right: 14px;
  bottom: 126px;
  text-align: center;
  background-color: #fff;
  width: 56px;
  height: 76px;
  padding: 6px 8px 10px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
  font-size: 15px;
  cursor: pointer;
  &:hover{
    .batch-export-img {
      background: linear-gradient(360deg, #FFFFFF 0%, #F6F6F6 100%);
    }
  }
  .batch-export-value{
    margin: 0 6px;
    width: 2em;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }

  .batch-export-img{
    border-radius: 6px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}

@media screen and (min-width: 1268px) {
  .batch-export {
    right: calc(50% - 550px - 70px) !important;
    bottom: 126px !important;
  }
}

.header {
  display: flex;
  align-items: center;
  .title {
    font-size: 22px;
    color: #333;
    margin-right: 10px;
  }
}


.drawer-content {
  height: 100%;
  .checked-list {
    max-height: calc(50vh - 69px - 32px - 20px);
    overflow-y: auto;
  }

  .checked-item {
    padding: 0 20px;
  }

  .checked-test {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 0 5px;

    .left {
      div {
        font-size: 14px;
        color: #8C8A8A;
      }
      .testName {
        font-size: 18px;
        color: #333;
      }
    }
  }

  .bottom-btn-group {
    position: relative;
    display: flex;
    justify-content: center;
    .clear {
      position: absolute;
      left: 20px;
    }
  }
}

::v-deep .el-drawer__container {
  width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  .el-drawer {
    height: auto !important;
    max-height: 50%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .el-drawer__header {
      background-color: #F6F6F6;
      padding: 20px;
      margin-bottom: 0;
    }

  }
}
</style>
<template>
  <article v-loading="loading">
    <RouterNav class="mb-10" :name="name" v-if="superAdmin === '2'"></RouterNav>
    <h1 class="title" v-else>机房考试</h1>
    <section>
      <div class="select-column">
        <el-select class="search-select" v-model="gradeTypeId" @change="changeGrade" v-if="superAdmin==='2'">
          <el-option v-for="item in gradeList" :key="item.gradeId" :label="item.gradeName" :value="item.gradeId"></el-option>
        </el-select>
        <el-select class="search-select" v-model="classId">
          <el-option v-for="item in classList" :key="item.clazzId" :label="item.clazzName" :value="item.clazzId"></el-option>
        </el-select>
        <el-input v-model="testName" placeholder="请输入考试名称搜索" clearable style="width: 200px;margin-right: 10px;" />
        <el-date-picker
            class="date-picker"
            v-model="dateRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <el-button class="btn-check" @click="refresh">查询</el-button>
      </div>
      <div class="content">
        <template v-for="(item, index) in list">
          <div class="list-item" :key="item.id">
            <div v-if="item.evaluateFlag === 1" class="check">
              <img class="check-icon" :src="item.checked ? require('@/assets/checked-icon.png') :require('@/assets/check-icon.png')" alt="" @click.stop="changeCheck(item, index)">
            </div>
            <div class="list-item__left">
              <h4 class="list-item__left-name">{{ item.testName }}</h4>
              <p class="list-item__left-desc">
                <span style="margin-right: 14px;">{{ item.gradeName }}</span>
                <span>{{ isSuperTeacher ? item.classNameList : item.clazzName }}</span>
                <span v-if="item.evaluateFlag === 1" style="margin: 0 14px;">{{ item.count }}/{{ item.totalCount }}人</span>
                <span v-if="item.evaluateFlag === 1">{{ setShow(item.avgScore) }}/{{ item.totalScore }}分</span>
              </p>
              <p class="list-item__left-desc">考试时间：{{ item.dateStart }}~{{ item.dateEnd }}</p>
              <p v-if="item.evaluateFlag === 1" class="list-item__left-desc">试题：{{ item.paperGroupName }}</p>
            </div>
            <div class="list-item__right">
              <div v-if="item.evaluateFlag !== 1" class="evaluate-flag"> {{ item.evaluateFlag === 0 ? statusStrMap[item.statusStr] : evaluateFlagMap[item.evaluateFlag] }}</div>
              <el-button class="btn-check" :class="{'btn-disable':item.statusName==='未开始', 'disabled':item.evaluateFlag !== 1}" :disabled="item.evaluateFlag !== 1" @click="examDetail(item)">查看报告
              </el-button>
            </div>
          </div>
          <div :key="item.id" class="list-item__line"></div>
        </template>
        <div class="text-center mt-20" v-if="totalCount>20">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pageIndex"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </section>

    <BatchExportButton :list.sync="list" />
  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav.vue'
// import Dialog from './components/Dialog.vue'
import {
  getGroupByGrade,
  getNormalOfficialList,
  getNormalOfficialListV2,
  getSuperOfficialList,
  getSuperOfficialListV2
} from "@/api/official";
import BatchExportButton from "@/views/exam/components/BatchExportButton.vue";

// copy element-ui 的 backtop 代码
const cubic = value => Math.pow(value, 3);
const easeInOutCubic = value => value < 0.5
    ? cubic(value * 2) / 2
    : 1 - cubic((1 - value) * 2) / 2;

export default {
  name: 'officialList',
  components: {
    BatchExportButton,
    RouterNav,
    // Dialog
  },
  data() {
    return {
      loading: false,
      name: '',
      schoolId: '',
      gradeList: [{gradeId: '', gradeName: '全部年级', clazzList: []}],
      gradeTypeId: '',
      classList: [{clazzId: '', clazzName: '全部班级'}],
      classId: '',
      testName: '',
      dateRange: [],
      list: [],
      checkedList: [],
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      superAdmin: 0,
      evaluateFlagMap: { 0: '未评测', 1: '已正常评测', 2: '评测失败', 3: '等待评测', 4: '评测中' },
      statusStrMap: { 1: '考试进行中', 2: '未上传', 3: '已上传', 4: '上传失败' }
    }
  },
  computed: {
    isSuperTeacher() {
      return this.superAdmin === '2'
    },
  },
  created() {
    sessionStorage.setItem('classExamDetail', JSON.stringify({}))
    if(this.$route.query.classId){
      this.classId = this.$route.query.classId
    }
    this.superAdmin = sessionStorage.getItem('superAdmin')
    // 只要是ai英语课堂(aiClient)客户端登录时，默认非超级教师
    const loginFrom = sessionStorage.getItem('loginFrom')
    if(loginFrom === 'ai-client'){
      this.superAdmin = 0
    }
    let schoolId
    if (this.superAdmin === '2') {
      schoolId = this.$route.query.schoolId
    } else {
      schoolId = sessionStorage.getItem('schoolId')
    }
    const {name} = this.$route.query
    this.name = name
    this.schoolId = schoolId
    this.getList()
    this.getGradeTypeById()
  },
  methods: {
    changeCheck(item, index) {
      this.$set(this.list[index], 'checked', !item.checked)
      this.checkedList = this.list.filter(item => item.checked)
    },
    handleSizeChange(val) {
      this.pageIndex = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getList()
    },
    examDetail(item) {
      if (item.statusName === '未开始') {
        return
      }
      const {provinceId, section} = this.$route.query
      const unfinishNum = item.totalCount - item.count
      const dataParam = {
        provinceId,
        section,
        batchNum: item.batchNum,
        clazzId: item.clazzId,
        groupId: item.groupId,
        name: '查看报告'
      }
      sessionStorage.setItem('classExamDetail', JSON.stringify(item))
      this.$router.push({
        name: 'ClassExamDetail',
        query: dataParam
      })
    },
    changeGrade(val) {
      const {clazzList} = (this.gradeList.filter(item => item.gradeId === val))[0]
      this.classList = [{clazzId: '', clazzName: '全部班级'}, ...clazzList]
      this.classId = ''
      this.refresh()
    },
    refresh() {
      this.pageIndex = 1
      this.getList()
    },
    async getList() {
      const dataParam = {
        testName: this.testName
      }
      if (Array.isArray(this.dateRange) && this.dateRange.length > 1) {
        dataParam.dateStart = this.common.formatDate('yyyy-MM-dd hh:mm:ss', this.dateRange[0])
        dataParam.dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm:ss', this.dateRange[1])
      }
      const {pageIndex} = this
      const {pageSize} = this
      this.loading = true
      let res
      try {
        if (this.superAdmin === '2') {
          dataParam.schoolId = this.schoolId
          if (this.gradeTypeId !== '') dataParam.gradeId = this.gradeTypeId
          if (this.classId !== '') dataParam.clazzId = this.classId
          // res = await getSuperOfficialList(dataParam, pageIndex, pageSize)
          res = await getSuperOfficialListV2(dataParam, pageIndex, pageSize)
        } else {
          if (this.classId !== '') dataParam.clazzId = this.classId
          // res = await getNormalOfficialList(dataParam, pageIndex, pageSize)
          res = await getNormalOfficialListV2(dataParam, pageIndex, pageSize)
        }
        if (res.state === '11') {
          const {pageContents, total, page, pageSize} = res.data
          this.pageIndex = page
          this.pageSize = pageSize
          this.list = pageContents.map(item => {
            item.checked = false
            if (Array.isArray(item.clazzList)) {
              const classNameList = item.clazzList.map(item => item.clazzName)
              item.classNameList = classNameList.join('，')
            }
            return item
          })
          this.totalCount = total
          this.scrollToTop()
        }
      } catch (e) {
      }
      this.loading = false
    },
    async getGradeTypeById() {
      if (this.superAdmin === '2') {
        try {
          let res = await getGroupByGrade(this.schoolId)
          if (res.state === '11') {
            const {gradeList} = res.data
            this.gradeList = [...this.gradeList, ...gradeList]
            gradeList.forEach(item => {
              this.gradeList[0].clazzList = this.gradeList[0].clazzList.concat(item.clazzList)
            })
            this.classList = [{clazzId: '', clazzName: '全部班级'}, ...this.gradeList[0].clazzList]
          }
        } catch (e) {
        }
      } else {
        this.$axios({
          method: 'post',
          url: this.$urls.getGradeTypeById,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.state === '11') {
            const {info} = response
            let list = []
            for (let i = 0, len = info.length; i < len; i += 1) {
              if (info[i]) list.push(info[i])
            }
            // 新旧接口返回对字段不一致，需要处理一下
            list = list.map(item => {
              item.clazzId = item.classId
              item.clazzName = item.className
              return item
            })
            this.classList = [...this.classList, ...list]
          }
        })
      }
    },
    /**
     * ElementUI 的 backtop 代码
     */
    scrollToTop() {
      const el = document.querySelector('#app');
      const beginTime = Date.now();
      const beginValue = el.scrollTop;
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16));
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500;
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress));
          rAF(frameFunc);
        } else {
          el.scrollTop = 0;
        }
      };
      rAF(frameFunc);
    },
    // 设置小数点
    setShow(score){
      return (typeof score === "number" ? score.toFixed(1) : score)
    }
  }
}
</script>

<style scoped lang="scss">
.mb-10 {
  margin-bottom: 10px !important;
}

.title {
  height: 84px;
  line-height: 84px;
  padding-left: 28px;
  font-size: 20px;
}

.select-column {
  @include flex(flex-start);
  background-color: #fff;
  height: 56px;
  padding-left: 28px;
  margin-bottom: 1px;

  .search-select {
    width: 140px;
    margin-right: 10px;
  }

  .date-picker {
    border: 1px solid #DCDFE6;
    margin-right: 10px;
  }
}

.content {
  background-color: #fff;
  padding: 10px 0;

  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 56px;
    border-radius: 8px;
    position: relative;

    &:hover {
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .08);
    }

    .check {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .check-icon{
        width: 20px;
        height: 20px;
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 880px;

      &-name {
        color: #333333;
        font-size: 17px;
      }

      &-desc {
        color: #999999;
        font-size: 15px;
        margin-top: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__right {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      .evaluate-flag{
        color: #FF3C30;
        margin-right: 14px;
        font-size: 15px;
      }
      .btn-setting {
        color: #309AF2;
        border: 1px solid #309AF2;
      }

      button {
        width: 100px;
        height: 40px;
        font-size: 15px;
      }
    }

  }

  .list-item__line {
    height: 1px;
    background-color: #F1F1F1;
    margin: 0 28px;
  }

}

.btn-check {
  width: 70px;
  color: #FFFFFF;
  border: none;
  background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
  &.disabled{
    background: #C0E0FB;
    &:hover{
      background: #C0E0FB;
    }
  }
  &:hover {
    background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
  }
}

.btn-disable {
  background: #C0E0FB !important;
  cursor: not-allowed;
}


</style>
